import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useRouteMatch,
  useParams,
} from "react-router-dom";

import Protected from "./Protected";

import NotFound from "../NotFound/NotFound";
import Login from "../Auth/Login";
import ForgotPassword from "../Auth/ForgotPassword";
import DashLayout from "./../Dashboard/DashLayout";
import Dashboard from "../Dashboard/Dashboard";
import AdminProfile from "../Dashboard/Admin/AdminProfile";
import AddMember from "../Dashboard/Admin/AddMember";
import AdminSetting from "../Dashboard/Admin/AdminSetting";
import VerificationPageAdmin from "../Dashboard/Admin/VerificationPageAdmin";
import Footer from "../Dashboard/Layout/Footer";
import ResetPassword from "../Auth/ResetPassword";
import ErrorTable from "../Dashboard/ProcessDataTables/ErrorTable";
import SuccesTable from "../Dashboard/ProcessDataTables/SuccesTable";
import InProgressTable from "../Dashboard/ProcessDataTables/InProgressTable";
import PendingTable from "../Dashboard/ProcessDataTables/PendingTable";
import SampleTable from "../Dashboard/ProcessDataTables/SampleTable";
import UploadLinks from "../Dashboard/UploadLinks";

import VideoPlayerTrial from "../Dashboard/ProcessDataTables/VideoPlayerTrial";
import VideoPlayer360 from "../VideoPlayer/VideoPlayer360";
import Rebrand from "../VidPlayerRebrand/Rebrand";
import AddClient from "../Dashboard/Client/AddClient";
import ClientDataTable from "../VidPlayerRebrand/ClientDataTable";
import VideoPlayerClient360 from "../VidPlayerRebrand/VideoPlayerClient360";

import VideoPlayerPublic360 from "../VidPlayerPublic/VideoPlayerPublic360";
import PublicDataTable from "../VidPlayerPublic/PublicDataTable";
import VideoPlayerDOC360 from "../VidPlayerForDOC/VideoPlayerDOC360";
import SampleTryTable from "../Dashboard/ProcessDataTables/SampleTryTable";
import ArchiveTable from "../Dashboard/ProcessDataTables/ArchiveTable";
import PrivateUrlLogs from "../VidPlayerRebrand/PrivateUrlLogs";
import RegisterPrivateUrlClient from "../Dashboard/Client/RegisterPrivateUrlClient";
import PrivateUrlClientData from "../VidPlayerRebrand/PrivateUrlClientData";
import UnprocessedTable from "../Dashboard/ProcessDataTables/UnprocessedTable";
function WebRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route
          exact
          path="/reset-password/:access_token"
          element={<ResetPassword />}
        />
        <Route
          exact
          path="/verification-page/:access_token"
          element={
            <>
              <VerificationPageAdmin />
              <Footer />
            </>
          }
        />
        <Route
          exact
          path="/dashboard"
          element={
            <Protected>
              <DashLayout>
                <Dashboard />
              </DashLayout>
            </Protected>
          }
        />
        {/* <Route
          exact
          path="/sampletable"
          element={
            <Protected>
              <DashLayout>
                <SampleTable />
              </DashLayout>
            </Protected>
          }
        /> */}
        <Route
          exact
          path="/sampletrytable"
          element={
            <Protected>
              <DashLayout>
                <SampleTryTable />
              </DashLayout>
            </Protected>
          }
        />
        
        <Route
          exact
          path="/errorstate"
          element={
            <Protected>
              <DashLayout>
                <ErrorTable />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/pendingstate"
          element={
            <Protected>
              <DashLayout>
                <PendingTable />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/successstate"
          element={
            <Protected>
              <DashLayout>
                <SuccesTable />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/inprogressstate"
          element={
            <Protected>
              <DashLayout>
                <InProgressTable />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/archivestate"
          element={
            <Protected>
              <DashLayout>
                <ArchiveTable />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/unprocessedstate"
          element={
            <Protected>
              <DashLayout>
                <UnprocessedTable />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/admin-profile"
          element={
            <Protected>
              <DashLayout>
                <AdminProfile />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/add-member"
          element={
            <Protected>
              <DashLayout>
                <AddMember />
              </DashLayout>
            </Protected>
          }
        />
        {/* <Route
          exact
          path="/add-client"
          element={
            <Protected>
              <DashLayout>
                <AddClient />
              </DashLayout>
            </Protected>
          }
        /> */}
        <Route
          exact
          path="/register-private-url-client"
          element={
            <Protected>
              <DashLayout>
                <RegisterPrivateUrlClient />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/upload-links"
          element={
            <Protected>
              <DashLayout>
                <UploadLinks />
              </DashLayout>
            </Protected>
          }
        />
        
        <Route
          exact
          path="/admin-setting"
          element={
            <Protected>
              <DashLayout>
                <AdminSetting />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/videoplayertrial"
          element={
            <Protected>
              <DashLayout>
                <VideoPlayerTrial />
              </DashLayout>
            </Protected>
          }
        />
        
        <Route
          exact
          path="/rebrand"
          element={
            <Protected>
              <DashLayout>
                <Rebrand />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/private-url-logs"
          element={
            <Protected>
              <DashLayout>
                <PrivateUrlLogs />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/client-data"
          element={
            <Protected>
              <DashLayout>
                <ClientDataTable />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/private-url-client-data"
          element={
            <Protected>
              <DashLayout>
                <PrivateUrlClientData />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/public-url-share"
          element={
            <Protected>
              <DashLayout>
                <PublicDataTable />
              </DashLayout>
            </Protected>
          }
        />

        <Route path="/*" element={<NotFound />} />
        {/* For Video Player */}
        <Route
          exact
          // path="/videoplayer360/:diamond_id/:supplier_id/:token"
          path="/videoplayer360/:diamond_id/:supplier_id"
          element={<VideoPlayer360 />}
        />
        <Route
          exact
          // path="/videoplayerclient360/:diamond_id/:access_key/:web_url/:client_id"
          path="/videoplayerclient360/:diamond_id/:supplier_id/:client_id"
          element={<VideoPlayerClient360 />}
        />
        <Route
          exact
          path="/videoplayerpublic360/:diamond_id/:supplier_id/:client_id/:token/:hit_count/:uuid"
          element={<VideoPlayerPublic360 />}
        />
        <Route
          exact
          path="/videoplayerdoc360/:diamond_id/:supplier_id"
          element={<VideoPlayerDOC360 />}
        />
        <Route
          exact
          path="/videoplayerdoc360/:mobile/:diamond_id/:supplier_id"
          element={<VideoPlayerDOC360 />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default WebRoutes;
