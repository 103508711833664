import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { positions, types } from "react-alert";
import { useAlert } from "react-alert";
import "./../../assets/css/login.css";

const ResetPassword = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const params = useParams();

  const [showPassowrd, setShowPassowrd] = useState(false);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const loginCheck = String(localStorage.getItem("logged_in"));

  // Check user logged in or not
  //if use logged in - Redirect on Dashboard
  useEffect(() => {
    const loginCheck = String(localStorage.getItem("logged_in"));
    if (loginCheck == "true") {
      navigate("/dashboard");
    }
  });
  const handelsubmit = async (event) => {
    event.preventDefault();
    document.getElementById(
      "loginBtn"
    ).innerHTML = `<span class="load loading"></span>`;
    const new_password = event.target.newPassword.value;
    const confirm_password = event.target.confirmPassword.value;
    const access_token = params.access_token;

    console.log("new_pass: ", new_password);
    console.log("confirm_pass: ", confirm_password);
    var BASE_URL = SERVER_URL + "/api/admin/reset-password";
    let resData = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: new_password,
        confirm_password: confirm_password,
        access_token: access_token,
      }),
    });
    const res = await resData.json();
    if (resData.ok) {
      alert.show(`${res.message}`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
      setTimeout(() => {
        navigate("/");
        document.getElementById("loginBtn").innerHTML = `Reset`;
      }, 2000);
    } else {
      alert.show(`${res.message}`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      document.getElementById("loginBtn").innerHTML = `Reset`;
    }
  };

  // ================| Show/Hide Password |========================
  function hideShowPassword() {
    let new_password = document.getElementById("new_password");
    let confirm_password = document.getElementById("confirm_password");
    if (showPassowrd) {
      new_password.type = "password";
      confirm_password.type = "password";
      setShowPassowrd(false);
    } else {
      new_password.type = "text";
      confirm_password.type = "text";
      setShowPassowrd(true);
    }
  }
  return (
    <>
      <section className="login-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-6">
              <div className="wrap justify-content-center d-md-flex">
                <div className="login-wrap p-4 p-md-5">
                  <div className="w-100">
                    <h3 className="mb-4 diamondsOnCallTitle">
                      Reset Passowrd
                    </h3>
                    {/* <h5 className="mb-4 form_title">Reset Passowrd</h5> */}
                  </div>
                  <form
                    action="#"
                    onSubmit={handelsubmit}
                    className="signin-form"
                  >
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        New Password
                      </label>
                      <input
                        id="new_password"
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        name="newPassword"
                        required
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group mb-1">
                      <label className="label" htmlFor="name">
                        Confirm Password
                      </label>
                      <input
                        id="confirm_password"
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        required
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <div className="form-check form-check-flat form-check-primary">
                        <label className="form-check-label">
                          Show Password{" "}
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onClick={() => hideShowPassword()}
                          />
                          <i class="input-helper"></i>
                        </label>
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        id="loginBtn"
                        type="submit"
                        className="form-control btn btn-primary rounded signInBtn px-3"
                      >
                        Reset
                      </button>
                    </div>
                    <div className="form-group d-md-flex forgot_password">
                      <Link to="/forgot-password">
                        <i className="fa fa-sign-in" aria-hidden="true"></i>{" "}
                        Back to Forgot Password
                      </Link>
                    </div>
                  </form>
                </div>
                {/* <div className="img"></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
