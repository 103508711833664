import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { positions, types } from "react-alert";
import { useAlert } from "react-alert";
import "./../../assets/css/login.css";

function ForgotPassword() {
  const navigate = useNavigate();
  const alert = useAlert();

  const [showPassowrd, setShowPassowrd] = useState(false);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const loginCheck = String(localStorage.getItem("logged_in"));

  // Check user logged in or not
  //if use logged in - Redirect on Dashboard
  useEffect(() => {
    const loginCheck = String(localStorage.getItem("logged_in"));
    if (loginCheck == "true") {
      navigate("/dashboard");
    }
  });
  const [for_email, setForEmail] = useState(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm
  );

  //==================| On submit function |=======================
  const handelsubmit = async (event) => {
    event.preventDefault();
    document.getElementById(
      "loginBtn"
    ).innerHTML = `<span class="load loading"></span>`;
    const email = event.target.username.value;
    if (!email.match(for_email)) {
      alert.show(`Enter Valid Email`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      document.getElementById("loginBtn").innerHTML = `Send`;
      return false;
    }
    console.log("email: ", email);
    var BASE_URL = SERVER_URL + "/api/admin/forgot-password";
    let resData = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    const res = await resData.json();
    if (resData.ok) {
      alert.show(`${res.message}`, {
        position: positions.TOP_CENTER,
        type: types.SUCCESS,
      });
      setTimeout(() => {
        document.getElementById("loginBtn").innerHTML = `Sent`;
      }, 4000);
    } else {
      alert.show(`${res.message}`, {
        position: positions.TOP_CENTER,
        type: types.ERROR,
      });
      document.getElementById("loginBtn").innerHTML = `Sent`;
    }

    // To Redirect on Login
  };

  return (
    <section className="login-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-6">
            <div className="wrap d-md-flex justify-content-center">
              <div className="login-wrap p-4 p-md-5">
                <div className="w-100">
                  <h3 className="mb-4 diamondsOnCallTitle">Forgot Passowrd</h3>
                  {/* <h5 className="mb-4 form_title">Forgot Passowrd</h5> */}
                </div>
                <form
                  action="#"
                  onSubmit={handelsubmit}
                  className="signin-form"
                >
                  <div className="form-group mb-3">
                    <label className="label" htmlFor="name">
                      Username/Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      name="username"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group">
                    <button
                      id="loginBtn"
                      type="submit"
                      className="form-control btn btn-primary rounded signInBtn px-3"
                    >
                      Send
                    </button>
                  </div>
                  <div className="form-group d-md-flex forgot_password">
                    <Link to="/">
                      <i className="fa fa-sign-in" aria-hidden="true"></i> Back
                      to Sign In
                    </Link>
                  </div>
                </form>
              </div>
              {/* <div className="img"></div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
